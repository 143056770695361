import React, { useState, createContext, useEffect } from "react"

import axios from "axios"

export const appContext = createContext()

const Provider = props => {
  const [appData, setData] = useState({
    ids: null,
    videos: null,
    channels: null,
  })

  useEffect(() => {
    console.log("=== Context Provider ===")
  })

  useEffect(() => {
    if (!appData.ids) {
      console.log("[ LOAD ] ==> appData.ids")

      axios
        .get(
          `https://sheets.googleapis.com/v4/spreadsheets/1WTeVdeZkMW_dwERYgPN8SjNRoXw5aMzlTxt4RHamgfg/values/data!A1:D999?majorDimension=COLUMNS&key=${process.env.GATSBY_GOOGLE_API_KEY}`
        )
        .then(response => {
          const columns = response.data.values
          // console.log("columns", columns)

          const ids = {
            videos: columns[columns.findIndex(column => column[0] === "videos")],
            channels:
              columns[columns.findIndex(column => column[0] === "channels")],
          }
          // console.log("ids", ids)
          setData({
            ...appData,
            ids,
          })
        })

      // axios
      //   .get(
      //     `https://spreadsheets.google.com/feeds/list/1WTeVdeZkMW_dwERYgPN8SjNRoXw5aMzlTxt4RHamgfg/od6/public/values?alt=json`
      //   )
      //   .then(response => {
      //     const entries = response.data.feed.entry

      //     let videos = []
      //     let channels = []

      //     entries.forEach(entry => {
      //       videos = [...videos, entry.gsx$videos.$t]
      //       channels = [...channels, entry.gsx$channels.$t]
      //       if (
      //         videos.length === entries.length &&
      //         channels.length === entries.length
      //       ) {
      //         const ids = {
      //           videos,
      //           channels,
      //         }
      //         // console.log("ids", ids)
      //         setData({
      //           ...appData,
      //           ids,
      //         })
      //       }
      //     })
      //   })
    }
  }, [appData])

  return (
    <appContext.Provider
      value={{
        appData,
        setAppData: data => setData(data),
      }}
    >
      {props.children}
    </appContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
