import React, { useEffect } from "react"
import { Link as RouterLink } from "gatsby"
import { useLocation } from "@reach/router"

// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles"
import {
  CssBaseline,
  Container,
  Box,
  Typography,
  Grid,
  Link as StyleLink,
  Button,
  useMediaQuery,
  Paper,
} from "@material-ui/core"

let theme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      default: "#212121",
    },
    // primary: {
    //   light: "#d0df8a",
    //   main: "#C5D86D",
    //   dark: "#89974c",
    //   contrastText: "#000",
    // },
    // secondary: {
    //   light: "#e9ebcf",
    //   main: "#E4E6C3",
    //   dark: "#9fa188",
    //   contrastText: "#000",
    // },
    primary: {
      light: "#ef714c",
      main: "#EC4E20",
      dark: "#a53616",
      contrastText: "#fff",
    },
    // secondary: {
    //   light: "#ffaa37",
    //   main: "#FF9505", // jaune
    //   dark: "#b26803",
    //   contrastText: "#000",
    // },
    // secondary: {
    //   light: "#b790b3",
    //   main: "#A675A1", // violet foncé vif
    //   dark: "#745170",
    //   contrastText: "#fff",
    // },

    secondary: {
      light: "#b5a8cb",
      main: "#A393BF", // violet foncé discret
      dark: "#726685",
      contrastText: "#000",
    },

    // secondary: {
    //   light: "#cebff3",
    //   main: "#C2AFF0", // violet clair
    //   dark: "#877aa8",
    //   contrastText: "#000",
    // },
    // secondary: {
    //   light: "#9ab6d7",
    //   main: "#81A4CD", // bleu
    //   dark: "#5a728f",
    //   contrastText: "#000",
    // },
    // secondary: {
    //   light: "#60b3f0",
    //   main: "#39A0ED", // bleu vif
    //   dark: "#2770a5",
    //   contrastText: "#000",
    // },
    // secondary: {
    //   light: "#b0daf3",
    //   main: "#9DD1F1", // bleu ciel
    //   dark: "#6d92a8",
    //   contrastText: "#000",
    // },
  },
  typography: {
    h3: {
      fontWeight: 300,
    },
  },
})
theme = responsiveFontSizes(theme)

const Layout = ({ children }) => {
  // const { logo } = useStaticQuery(graphql`
  //   query {
  //     logo: file(relativePath: { eq: "logo.png" }) {
  //       childImageSharp {
  //         fixed(height: 60) {
  //           ...GatsbyImageSharpFixed
  //         }
  //       }
  //     }
  //   }
  // `)

  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const isSm = useMediaQuery(theme.breakpoints.only("sm"))
  const isMd = useMediaQuery(theme.breakpoints.only("md"))
  const isLg = useMediaQuery(theme.breakpoints.only("lg"))
  const isXl = useMediaQuery(theme.breakpoints.only("xl"))

  const location = useLocation()

  useEffect(() => {
    console.log("=== Layout ===")
  })

  const MenuItem = ({ children, slug }) => (
    <Box
      paddingLeft={{ xs: 1, md: 2 }}
      paddingRight={{ xs: 1, md: 0 }}
      paddingY={{ xs: 1, md: 0 }}
    >
      <Typography noWrap>
        <Button
          variant="outlined"
          color="secondary"
          component={RouterLink}
          to={slug}
          disabled={slug === location.pathname ? true : false}
        >
          {children}
        </Button>
      </Typography>
    </Box>
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl">
        <Box margin={{ xs: 0, sm: 4, md: 6, lg: 8, xl: 10 }}>
          <Grid
            container
            direction="column"
            spacing={
              isXs ? 2 : isSm ? 4 : isMd ? 6 : isLg ? 8 : isXl ? 10 : null
            }
          >
            <Box
              display={
                useMediaQuery(theme.breakpoints.down("sm")) ? "block" : "flex"
              }
              align="center"
              padding={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
            >
              <StyleLink color="secondary" component={RouterLink} to="/">
                <Box paddingY={2}>
                  <Paper style={{ overflow: "hidden", width: "fit-content" }}>
                    <Box
                      paddingX={2}
                      paddingY={1}
                      bgcolor="secondary.main"
                      color="secondary.contrastText"
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography variant="h3">
                        <b>#</b>Rando<b>Passion</b>
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
              </StyleLink>

              {/* <Box style={{ flexGrow: 1 }}>
                <Img fixed={logo.childImageSharp.fixed} />
              </Box> */}

              {/* stretch */}
              <Box flexGrow={1} />

              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                padding={{ xs: 0, sm: 1 }}
              >
                <MenuItem slug="/">vidéos</MenuItem>
                <MenuItem slug="/channels/">chaînes</MenuItem>
                <MenuItem slug="/about/">à propos</MenuItem>
                <MenuItem slug="/participate/">participer</MenuItem>
              </Box>
            </Box>

            <Grid item xs>
              {/* <Box marginY={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}> */}
              {children}
              {/* </Box> */}
            </Grid>

            <Grid item xs>
              <Box marginY={{ xs: 2, sm: 4, md: 6, lg: 8, xl: 10 }}>
                <Typography align="center" style={{ color: "white" }}>
                  Création web {new Date().getFullYear()} :{` `}
                  <StyleLink
                    href="https://pro.guslyon.fr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    GusLyon.fr
                  </StyleLink>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default Layout
